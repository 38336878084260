.roadmaps{
  display: flex;
  height: 100%;
  .diagram{
    flex-grow: 1;
    .toolbar{
      position: absolute;
      z-index: 1;
      right: 0;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  .drag-placeholder{
    background-color: rgba(0,0,0,0.1) !important;
    margin: 2px 4px;
  }
  .roadmap-item{
    display: flex !important;
    padding: 2px !important;
    .handle
    {
      display: none;
      width:10px;
      overflow: hidden;
      line-height: 5px;
      padding: 3px 4px;
      cursor: move;
      vertical-align: middle;
      color: #ccc;
      margin-right: .3em;

      &::after{
        content: '.. .. .. ..'
      }
    }
    &:hover .handle{
      display: inline-block;
    }
    &.dragging{
      display: none !important;
    }
    .text{
      flex-grow: 1;
      text-align: center;
      margin-top: auto;
      margin-bottom: auto;
    }
    .action.input{
      z-index:1;
    }
    .editable-input-btn{
      display: none;
    }
    &:hover .editable-input-btn{
      margin-left: auto;
      margin-right: 0;
      display: inline-block;
    }
  }
  .react-flow{
    .react-flow__node{
      border-color: gray;
      box-shadow: 0 2px 3px 0px gray;
      text-shadow: 0 0 2px white;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;

      &.selected,&:hover{
        border-color: cornflowerblue;
        box-shadow: 0 2px 2px 0px cornflowerblue;
        .react-flow__handle-left{
          box-shadow: -2px 0px 2px 0px cornflowerblue !important;
        }
        .react-flow__handle-right{
          box-shadow: 2px 0px 2px 0px cornflowerblue !important;
        }
      }
      .react-flow__handle{
        width: 14px;
        height: 30px;
        border-radius: 3px;
        &.react-flow__handle-left{
          box-shadow: -3px 0px 3px 0px gray;
        }
        &.react-flow__handle-right{
          box-shadow: 3px 0px 3px 0px gray;
        }
      }
    }
  }
  .context-menu{
    position: absolute;
  }
}

