.role{
  .column{
    //padding-left: 0.3rem !important;
    //padding-right: 0.3rem !important;
  }
  .role-name{
    padding: 0 !important;
    box-shadow: 1px 1px 5px #000, -1px -1pxx 5px #6f6f5f;
    //text-transform: uppercase;
  }
  .node-cards{
    .ui.card{
      margin-top:0;
      margin-bottom:0;
      margin:0;
      width: calc(33.33333333%);
      border-top: 1px solid;
      border-bottom: 1px solid;

      .content{
        padding-top: 4px;
        padding-bottom: 4px;
        .description{
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
        }
        &.extra{
          padding-top: 1px;
          padding-bottom: 1px;
          //background: #e0e1e2;
          background: rgba(0,0,0,0.2);
        }
        .close-button{
          position: absolute;
          right: 4px;
          top: 4px;
          padding: 6px;
          display: none;
        }
      }
      &:hover .close-button{
        display: block;
      }
    }
  }
}
