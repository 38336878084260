#root{
  @import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
  font-family: 'Oswald', sans-serif;
  color: #444;
  height:100%;
  .app{
    height:100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    >.menu{
      height: 31px;
    }
    .page-container{
      max-height: calc( 100% - 31px );
      flex-grow: 1;
    }
  }
}
