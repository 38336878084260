.schedule{
  height: 100%;
  .sidebar{
    padding: 0 !important;
    .title{
      margin-top: 4px;
      margin-bottom: 0;
      text-align: center;
    }
    .role-item{
      border-radius: 5px;
      border: 1px solid gray;
      margin: 2px;
      .role{
        background: #e0e1e2;
        padding: 0 2px;
        text-align: center;
        font-size: 12px;
        line-height: 12px;
        position: relative;
        .toolbar{
          display: none;
          position: absolute;
          top:0;
          right:0;
          .button{
            padding:2px;
          }
        }
      }
      &:hover .toolbar{
        display: block;
      }
      .task{
        padding: 2px 1px;;
        .editable-task{
          //min-height:20px;
          position: relative;
          display: block;
          margin-right: 0;
          //border: 1px solid #ccc;

          &.red{
            color: red;
            .dropdown .divider.text{
              color: red;
            }
          }
          &.orange{
            color: orange;
            .dropdown .divider.text{
              color: orange;
            }
          }
          &.teal{
            color: teal;
            .dropdown .divider.text{
              color: teal;
            }
          }
          &.blue{
            color: blue;
            .dropdown .divider.text{
              color: blue;
            }
          }
          .toolbar{
            display: none;
            z-index: 1;
            position: absolute;
            top:0;
            left: 95%;
            height: 100%;
            .button{
              height: 100%;
              padding: 2px;
            }
            .dropdown{
              border-radius: 0px;
              width: 34px;
              .divider.text{
                height: 20px;
                width:100%;
                font-size: 14px;
                line-height:16px;
                text-align: center;
              }
              .text{
                text-align: center;
                width: 100%;
                font-weight: bold;
              }
              .item{
                padding: 0 2px !important;
              }
            }
          }
          &:hover .toolbar{
            display: inline-flex;
          }
          label{
            display: inline-block;
          }
        }
      }
    }
    .improve{
      position: absolute;;
      bottom: 0;
      right: 0;
    }
  }
  .day-grid{
    display: flex;
    flex-direction: column;
    height: calc( 100% + 2rem );
    .row{
      padding: 0;
      .column{
        padding: 0;
        display: flex;
        flex-direction: column;
      }
      &:nth-child(1){
        flex-grow: 4;
      }
      &:nth-child(2){
        flex-grow: 18;
        border-top: 1px solid #bbb;
      }
    }
    .day-header{
      text-align: center;
      font-weight: bold;
      background: #222;
      color: white;
      flex-grow: 0 !important;
    }
    .timeslot{
      flex-grow: 1;
      position: relative;
      padding: 2px;
      &:nth-child(odd){
        background: #f7f7f7;
      }
      .label{
        color: gray;
        font-size: 10px;
        position: absolute;
        top: 0;
        right: 2px;
        line-height: 10px;
      }
      .static-task{
        width: 100%;
        border: 1px solid #ccc;
        &:before{
          border-left: 1px solid;
          border-top: 1px solid;
        }
        .icon{
          right:8px;
          position: absolute;
          display: none;
          opacity: 0.8;
          font-size: 1rem;
        }
        &:hover .icon{
          display: inline-block;
        }
      }
    }
  }
}
